import * as React from "react";
import Header from "../components/layout/header";
import Banner from "../components/layout/banner";
import About from "../components/layout/about";
import Projects from "../components/layout/projects";
import Contact from "../components/layout/contact";
import Skills from "../components/layout/skills";
import Footer from "../components/layout/footer";
import "@fontsource/della-respira";
import "@fontsource/anonymous-pro";
import "@fontsource/poppins";
import "../styles/styles.scss";
import { Helmet } from "react-helmet";
import favicon from "../styles/images/favicon.ico"
const IndexPage = () => {
  return (
    <div className="root">
       <Helmet>
         <meta charSet="utf-8" />
         <meta name="Michelle Collins Portfolio Site" content="Michelle Collins, software developer, portfolio site" />
         <title>Michelle Collins</title>
         <link rel="icon" href={favicon} />
         <link rel="canonical" href="https:www.mmgondran.com" />
       </Helmet>
       <Header />
       {/* <Banner />
       <About />
       <Skills />
       <Projects /> */}
       {/* <Contact />
       <Footer /> */}
    </div>
  );
};

export default IndexPage;
