import React from "react";
import { Link } from "gatsby";
//import favicon from "../../styles/images/mgicon.png"
const Header = () => {
  return (
    <header id="header home">
      <div className="container">
        <div className="inner-header">
          <div className="nav-logo">
            <Link to="#">
              <span className="icon">@wthmichelle</span>
              <div className="logo">
              <span className="handle">m</span>
              <span className="name">ichelle </span>{" "}
              <span className="handle">m</span>
              <span className="name">.</span>{" "}
              <span className="handle">collins</span>

              </div>
            </Link>
          </div>
          <div className="navigation">
            <nav>
              {/* <Link to="#about" className="nav-link">
                about
              </Link>
              {/* <Link to="#projects" className="nav-link">
                projects
              </Link> */}
              {/* <Link to="#contact" className="nav-link">
                contact
              </Link> */}
              {/* <Link to="#resume" className="nav-link">
                resume ⇣
              </Link> */}
            </nav>
          </div>
        </div>
      </div>
      <hr className="line"/>
    </header>
  );
};

export default Header;
